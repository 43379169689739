import request, { ac_headers } from "@/utils/studio7ApiService";

export function downloadPromptAudioFile(data) {
  return request({
    url: "/file-download-from-url",
    method: "post",
    responseType: "blob",
    data: data,
    headers: ac_headers()
  });
}
