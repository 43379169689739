<script>
import path from "path";
import { downloadPromptAudioFile } from "@/api/audio";

export default {
  name: "FileDownloader",
  methods: {
    downloadFile(data, fileName) {
      let fileURL = window.URL.createObjectURL(new Blob([data]));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        path.basename(fileName.replace(/\\\\/g, "/"))
      );
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    downloadCSVFile(data, fileName) {
      let fileURL = window.URL.createObjectURL(
        new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), data], {
          type: "text/csv;charset=utf-8"
        })
      );
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        path.basename(fileName.replace(/\\\\/g, "/"))
      );
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    //download file from url
    downloadFileFromURL(url) {
      let url_path = new URL(url);
      let url_array = url_path.pathname.split("/");
      let fileName = url_array.slice(-1)[0];

      let data = {
        path: url_path.pathname.replace(new RegExp(".*?/storage/"), ""),
        url: url,
        fileName: fileName,
        fileType: "audio/wav"
      };
      downloadPromptAudioFile(data)
        .then(response => {
          this.downloadFile(response.data, fileName);
        })
        .catch(err => {
          this.$message({
            message: err.message || __("unable to download data"),
            type: "error"
          });
        });
    }
  }
};
</script>
